import shouldTableSortByRenewTime from './shouldTableSortByRenewTime';
import type { MailFolderTableQuery } from 'owa-mail-list-store';
import { MailSortHelper, type SortColumn } from 'owa-mail-list-store';
import type ExtendedPropertyUri from 'owa-service/lib/contract/ExtendedPropertyUri';
import type SortResults from 'owa-service/lib/contract/SortResults';
import extendedPropertyUri from 'owa-service/lib/factory/extendedPropertyUri';
import propertyUri from 'owa-service/lib/factory/propertyUri';
import sortResults from 'owa-service/lib/factory/sortResults';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

/**
 * Gets the sortBy property depending on the folder
 * @param tableQuery tableQuery of the conversation type table for which to get the sortBy property
 * @return sortBy - a collection of the sort properties
 */
export default function getMailItemSortByProperty(tableQuery: MailFolderTableQuery): SortResults[] {
    let primarySortPropertyUri: string | undefined;
    let primaryPropertyUriPath: ExtendedPropertyUri | undefined;

    const sortBy = tableQuery.sortBy;
    switch (sortBy.sortColumn) {
        case 1:
            if (shouldTableSortByRenewTime(tableQuery)) {
                // When table supports sorting by renew time,
                // ReceivedOrRenewTime is used as primary sort
                primarySortPropertyUri = 'ReceivedOrRenewTime';
            } else if ('drafts' == folderIdToName(tableQuery.folderId)) {
                // use LastModifiedTime as the primary sort for Drafts folder.
                primarySortPropertyUri = 'ItemLastModifiedTime';
            } else if ('sentitems' == folderIdToName(tableQuery.folderId)) {
                // use DateTimeSent as the primary sort for SentItems folder.
                primarySortPropertyUri = 'DateTimeSent';
            }
            break;

        case 3:
            // Use the display name for sort column From and not the smtp address
            primaryPropertyUriPath = extendedPropertyUri({
                PropertyTag: '0x0042',
                PropertyType: 'String',
            });
            break;

        case 9:
            primarySortPropertyUri = 'DisplayTo';
            break;

        case 7:
            primarySortPropertyUri = 'Size';
            break;

        case 5:
            primarySortPropertyUri = 'Importance';
            break;

        case 8:
            primarySortPropertyUri = 'Subject';
            break;

        case 14:
            primarySortPropertyUri = 'Categories';
            break;

        case 15:
            primarySortPropertyUri = 'ReturnTime';
            break;

        case 16:
            primarySortPropertyUri = 'DateTimeReceived';
            break;

        default:
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(
                'getMailItemSortByProperty: sort column not supported' + sortBy.sortColumn
            );
    }

    const sortByResults: SortResults[] = [];

    // Add primary SortResult
    if (primarySortPropertyUri) {
        sortByResults.push(
            sortResults({
                Order: sortBy.sortDirection,
                Path: propertyUri({ FieldURI: primarySortPropertyUri }),
            })
        );
    } else if (primaryPropertyUriPath) {
        sortByResults.push(
            sortResults({
                Order: sortBy.sortDirection,
                Path: primaryPropertyUriPath,
            })
        );
    }

    // For non-date sorts the secondary sort direction will always default to descending.
    // For date sort we want to honour users selection of direction
    const secondarySortDirection =
        tableQuery.sortBy.sortColumn == 1
            ? tableQuery.sortBy.sortDirection
            : MailSortHelper.DESCENDING_SORT_DIR;

    // Add secondary SortResult of DateTimeReceived for all sort types.
    // Currently only descending sort order is supported
    sortByResults.push(
        sortResults({
            Order: secondarySortDirection,
            Path: propertyUri({ FieldURI: 'DateTimeReceived' }),
        })
    );
    return sortByResults;
}
