//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ItemRowFragmentFragmentDoc } from './ItemRowFragment.interface';
export type ItemRowsQueryVariables = Types.Exact<{
    folderId: Types.Scalars['String'];
    searchFolderId?: Types.InputMaybe<Types.Scalars['String']>;
    sortBy: Types.SortByInput;
    pagingInfo: Types.PagingInfoInput;
    viewFilter: Types.ViewFilter;
    focusedViewFilter: Types.FocusedViewFilter;
    category?: Types.InputMaybe<Types.Scalars['String']>;
    mailboxInfo: Types.MailboxInfoInput;
    shapeName: Types.Scalars['String'];
    isScheduledFolder: Types.Scalars['Boolean'];
}>;
export type ItemRowsQuery = {
    __typename?: 'Query';
    itemRows?: {
        __typename?: 'ItemRowConnection';
        searchFolderId?: string | null;
        totalItemRowsInView?: number | null;
        edges: Array<{
            __typename?: 'ItemRowEdge';
            cursor: string;
            node: {
                __typename?: 'ItemRow';
                id: string;
                ItemId: any;
                AntispamUnauthenticatedSender?: boolean | null;
                Categories?: Array<string> | null;
                ConversationId?: any | null;
                DateTimeReceived?: string | null;
                DateTimeSent?: string | null;
                DisplayTo?: string | null;
                EntityNamesMap?: any | null;
                From?: any | null;
                HasAttachments?: boolean | null;
                HasProcessedSharepointLink?: boolean | null;
                IconIndex?: Types.IconIndex | null;
                Importance?: Types.Importance | null;
                InstanceKey?: string | null;
                IsDeliveryReceiptRequested?: boolean | null;
                IsDraft?: boolean | null;
                IsRead?: boolean | null;
                IsReadReceiptRequested?: boolean | null;
                IsSubmitted?: boolean | null;
                IsExternalSender?: boolean | null;
                ItemClass?: string | null;
                LastModifiedTime?: string | null;
                MentionedMe?: boolean | null;
                MessageToMe?: boolean | null;
                MessageCcMe?: boolean | null;
                Preview?: string | null;
                ReactionsCount?: number | null;
                ReceivedOrRenewTime?: string | null;
                ReturnTime?: string | null;
                Sensitivity?: Types.SensitivityType | null;
                Size?: number | null;
                SortOrderSource?: Types.ExecuteSearchSortOrder | null;
                SpotlightIsVisible?: boolean | null;
                Subject?: string | null;
                Flag?: {
                    __typename?: 'Flag';
                    FlagStatus?: Types.FlagStatus | null;
                    StartDate?: string | null;
                    DueDate?: string | null;
                    CompleteDate?: string | null;
                } | null;
                ParentFolderId?: {
                    __typename?: 'FolderId';
                    Id: string;
                    ChangeKey?: string | null;
                } | null;
            };
        }>;
        pageInfo: {
            __typename?: 'PageInfoWithCursorPagination';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor?: string | null;
        };
    } | null;
};
export const ItemRowsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ItemRows' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchFolderId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortByInput' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagingInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PagingInfoInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'viewFilter' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewFilter' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'focusedViewFilter' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FocusedViewFilter' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MailboxInfoInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'shapeName' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isScheduledFolder' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemRows' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'options' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'searchFolderId' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'searchFolderId' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'viewFilter' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'viewFilter' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'focusedViewFilter' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'focusedViewFilter' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'category' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'category' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'pagingInfo' },
                                            value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'pagingInfo' },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'commonRowsOptions' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'folderId' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folderId',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sortBy' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'sortBy' },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: {
                                                            kind: 'Name',
                                                            value: 'mailboxInfo',
                                                        },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mailboxInfo',
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'shape' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'ShapeName' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'shapeName',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: {
                                                            kind: 'Name',
                                                            value: 'IsScheduledFolder',
                                                        },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isScheduledFolder',
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'ItemRowFragment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cursor' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'searchFolderId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalItemRowsInView' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'endCursor' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hasNextPage' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hasPreviousPage' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'startCursor' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...ItemRowFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<ItemRowsQuery, ItemRowsQueryVariables>;
