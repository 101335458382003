import type { ConversationType } from 'owa-graph-schema';
import { TAG_EMAIL_HASHTAG } from 'owa-mail-fetch-tagged-email/lib/utils/constants';
import type ConverationType from 'owa-service/lib/contract/ConversationType';

export function mapOwsConversationTypeToGql(
    conv: ConverationType,
    folderId?: string
): ConversationType {
    const parentFolderId = conv.ParentFolderId?.Id || folderId;
    return {
        __typename: 'ConversationType',
        ConversationId: {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            Id: conv.ConversationId!.Id,
        },
        ConversationTopic: conv.ConversationTopic ?? '',
        UniqueRecipients: conv.UniqueRecipients,
        UniqueSenders: conv.UniqueSenders,
        LastDeliveryTime: conv.LastDeliveryTime ?? '',
        Categories: conv.Categories,
        FlagStatus: conv.FlagStatus,
        HasAttachments: conv.HasAttachments,
        MessageCount: conv.MessageCount,
        GlobalMessageCount: conv.GlobalMessageCount,
        UnreadCount: conv.UnreadCount ?? 0,
        GlobalUnreadCount: conv.GlobalUnreadCount,
        Size: conv.Size,
        ItemClasses: conv.ItemClasses,
        Importance: conv.Importance,
        ItemIds: conv.ItemIds,
        GlobalItemIds: conv.GlobalItemIds,
        LastModifiedTime: conv.LastModifiedTime,
        InstanceKey: conv.InstanceKey,
        Preview: conv.Preview,
        IconIndex: conv.IconIndex,
        DraftItemIds: conv.DraftItemIds,
        HasIrm: conv.HasIrm,
        LastDeliveryOrRenewTime: conv.LastDeliveryOrRenewTime ?? '',
        LastSentTime: conv.LastSentTime ?? '',
        GlobalMentionedMe: conv.GlobalMentionedMe,
        GlobalAtAllMention: conv.GlobalAtAllMention,
        SortOrderSource: conv.SortOrderSource,
        LastSender: conv.LastSender,
        From: conv.From,
        ParentFolderId: parentFolderId
            ? {
                  __typename: 'FolderId',
                  Id: parentFolderId,
              }
            : undefined,
        EntityNamesMap: conv.EntityNamesMap,
        HasExternalEmails: conv.HasExternalEmails,
        ReturnTime: conv.ReturnTime,
        IsTaggedForBigScreen: conv.Hashtags && conv.Hashtags.indexOf(TAG_EMAIL_HASHTAG) >= 0,
        HasSharepointLink: conv.HasSharepointLink,
        HasAttachmentPreviews: conv.HasAttachmentPreviews,
        HasProcessedSharepointLink: conv.HasProcessedSharepointLink,
        mentionedMe: conv.GlobalMentionedMe || conv.GlobalAtAllMention,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        id: conv.ConversationId!.Id,
        InferenceClassification: !conv.ConversationInferenceClassification ? 'Focused' : 'Other',
        ConversationToMe: conv.ConversationToMe,
        ConversationCcMe: conv.ConversationCcMe,
        ReactionsCount: conv.ReactionsCount ?? 0,
    };
}
