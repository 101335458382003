import type { IPoint } from '@fluentui/react/lib/Utilities';
import type { DatapointOptions } from 'owa-analytics-types';
import { addCoreDatapointConfig, addDatapointConfig } from 'owa-analytics-actions';
import type { SelectionDirection, TableView } from 'owa-mail-list-store';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { action } from 'satcheljs';
import isSelectMailItemCore from 'owa-mail-logging/lib/utils/isSelectMailItemCore';

const datapointOptions: DatapointOptions = { timeout: 90 * 1000 };

/**
 * Single select mail row action
 * @param tableView where the operation is being performed
 * @param rowKey the rowKey of the item to select
 * @param isUserNavigation whether user's click or action triggered this action
 * @param mailListItemSelectionSource The source of selection on mail item
 * @param eventTimestamp The timestamp of when user clicks on a row to select it.
 */
export const singleSelectRow = action(
    'SINGLE_SELECT_ROW',
    (
        tableView: TableView,
        rowKey: string,
        isUserNavigation: boolean,
        mailListItemSelectionSource: MailListItemSelectionSource,
        eventTimestamp?: number
    ) => {
        return isSelectMailItemCore(mailListItemSelectionSource, tableView, rowKey, undefined)
            ? addCoreDatapointConfig(
                  {
                      name: 'SelectMailItem',
                      options: { ...datapointOptions, eventTimestamp },
                  },
                  {
                      tableView,
                      rowKey,
                      isUserNavigation,
                      mailListItemSelectionSource,
                  }
              )
            : addDatapointConfig(
                  {
                      name: 'SelectMailItemNonCritical',
                      options: { ...datapointOptions, eventTimestamp },
                  },
                  {
                      tableView,
                      rowKey,
                      isUserNavigation,
                      mailListItemSelectionSource,
                  }
              );
    }
);

/**
 * Toggle selection state on the given row
 * @param tableView where the selection took place
 * @param rowKey the rowKey of the item to toggle selection on
 * @param isUserNavigation whether user's click or action triggered this action
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export const toggleSelectRow = action(
    'TOGGLE_SELECT_ROW',
    (
        tableView: TableView,
        rowKey: string,
        isUserNavigation: boolean,
        mailListItemSelectionSource: MailListItemSelectionSource,
        eventTimestamp?: number
    ) => {
        return isSelectMailItemCore(mailListItemSelectionSource, tableView, rowKey, undefined)
            ? addCoreDatapointConfig(
                  {
                      name: 'SelectMailItem',
                      options: { ...datapointOptions, eventTimestamp },
                  },
                  {
                      tableView,
                      rowKey,
                      isUserNavigation,
                      mailListItemSelectionSource,
                  }
              )
            : addDatapointConfig(
                  {
                      name: 'SelectMailItemNonCritical',
                      options: { ...datapointOptions, eventTimestamp },
                  },
                  {
                      tableView,
                      rowKey,
                      isUserNavigation,
                      mailListItemSelectionSource,
                  }
              );
    }
);

/**
 * After promise has returned following a selection change action
 * @param rowKey the rowKey of the item that selection changed on
 * @param tableView where the selection took place
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export const onAfterSelectionChanged = action(
    'ON_AFTER_SELECTION_CHANGED',
    (
        rowKey: string,
        tableView: TableView,
        mailListItemSelectionSource: MailListItemSelectionSource
    ) => ({
        rowKey,
        tableView,
        mailListItemSelectionSource,
    })
);

/**
 * Reset the list view expansion state (expanded conversation)
 */
export const resetListViewExpansionViewState = action('RESET_LIST_VIEW_EXPANSION_VIEW_STATE');

/**
 * Set the list view expansion state to second level expansion by setting loading to false and latestItemIdsInEachFork to null
 */
export const setSecondLevelListViewExpansion = action(
    'SET_SECOND_LEVEL_LIST_VIEW_EXPANSION',
    (rowKey: string) => ({ rowKey })
);

/**
 * Called when an item part is selected
 * @param nodeId the nodeId of the selected item part
 * @param itemId the itemId of the selected item part
 * @param allNodeIds the nodeIds of the conversation
 * @param tableView - where the selection took place
 * @param mailListItemSelectionSource The source of selection on item part
 */
export const itemPartSelected = action(
    'ITEM_PART_SELECTED',
    (
        nodeId: string,
        itemId: string,
        allNodeIds: string[],
        tableView: TableView,
        mailListItemSelectionSource: MailListItemSelectionSource
    ) => ({
        nodeId,
        itemId,
        allNodeIds,
        tableView,
        mailListItemSelectionSource,
    })
);

/**
 * Called when an item part is selected
 * @param rowKey the rowKey for the parent conversation of the item part
 * @param tableView - where the delete took place
 */
export const selectNextItemPart = action(
    'SELECT_NEXT_ITEM_PART',
    (rowKey: string, tableView: TableView) => ({
        rowKey,
        tableView,
    })
);

/**
 * Called to open the context menu for an expanded item part
 * @param contextMenuAnchor anchor from which the context menu will open
 * @param nodeId the Id of the item part that was selected
 * @param tableViewId the id of the table where the conversation belongs
 */
export const onItemPartContextMenu = action(
    'ON_ITEM_PART_CONTEXT_MENU',
    (contextMenuAnchor: IPoint, nodeId: string, tableViewId: string) => ({
        contextMenuAnchor,
        nodeId,
        tableViewId,
    })
);

/**
 * Select a single expanded item part
 * @param rowKey the instance key of the conversation
 * @param nodeIdToSelect the nodeId of the item part to select
 * @param allNodeIds of the conversation
 * @param tableViewId the id of the table where the conversation belongs
 * @param currentSelectedNodeIds the ids of currently selected itemparts in expanded conversation
 */
export const singleSelectItemPart = action(
    'SINGLE_SELECT_ITEM_PART',
    (
        rowKey: string,
        nodeIdToSelect: string,
        allNodeIds: string[],
        tableViewId: string,
        currentSelectedNodeIds?: string[]
    ) => ({
        rowKey,
        nodeIdToSelect,
        allNodeIds,
        tableViewId,
        currentSelectedNodeIds,
    })
);

/**
 * Toggle selection on an item part
 * @param nodeId the nodeId of the item part to toggle selection
 * @param isCtrlOrCmdKeyDown - whether the Control or Command key is down for this operation
 */
export const toggleSelectItemPart = action(
    'TOGGLE_SELECT_ITEM_PART',
    (nodeId: string, isCtrlOrCmdKeyDown: boolean) => ({
        nodeId,
        isCtrlOrCmdKeyDown,
    })
);

export const onItemPartSelectedOrChecked = action(
    'ON_ITEM_PART_SELECTED_OR_CHECKED',
    (nodeId: string, tableView: TableView, isCtrlOrCmdKeyDown: boolean) => ({
        nodeId,
        tableView,
        isCtrlOrCmdKeyDown,
    })
);

/**
 * Perform range selection from current selection to target item part's node id
 * @param tableView where the selection took place
 * @param targetNodeId the nodeId of the item to extend range selection to
 * @param isCtrlOrCmdKeyDown - whether the Control or Command key is down for this operation
 */
export const rangeSelectItemPart = action(
    'RANGE_SELECT_ITEM_PART',
    (tableView: TableView, targetNodeId: string, isCtrlOrCmdKeyDown: boolean) => ({
        tableView,
        targetNodeId,
        isCtrlOrCmdKeyDown,
    })
);

/**
 * Called when an item part should become the anchor for multi-selection purposes
 * @param nodeId the nodeId of the item part that should be set as the anchor
 */
export const setAnchorNodeInExpansion = action(
    'SET_ANCHOR_NODE_IN_EXPANSION',
    (nodeId: string) => ({
        nodeId,
    })
);

/**
 * Toggle between select all and clear selection on the table
 * @param tableView where the operation is being performed
 */
export const toggleSelectAllRows = action('TOGGLE_SELECT_ALL_ROWS', (tableView: TableView) => ({
    tableView,
}));

/**
 * Perform range selection from current selection to target row key
 * @param tableView where the selection took place
 * @param targetRowKey the rowKey of the item to extend range selection to
 * @param mailListItemSelectionSource The source of selection on mail item
 * @param isCtrlOrCmdKeyDown - whether the Control or Command key is down for this operation
 */
export const rangeSelectRow = action(
    'RANGE_SELECT_ROW',
    (
        tableView: TableView,
        targetRowKey: string,
        mailListItemSelectionSource: MailListItemSelectionSource,
        isCtrlOrCmdKeyDown?: boolean
    ) => ({
        tableView,
        targetRowKey,
        mailListItemSelectionSource,
        isCtrlOrCmdKeyDown,
    })
);

/**
 * Resets selection in the given table
 * @param tableView where the operation is being performed
 * @param mailListItemSelectionSource selection mode for the maillist item
 */
export const resetSelection = action(
    'RESET_SELECTION',
    (
        tableView: TableView,
        mailListItemSelectionSource: MailListItemSelectionSource,
        isUserNavigation?: boolean
    ) => ({
        tableView,
        mailListItemSelectionSource,
        isUserNavigation,
    })
);

/**
 * Resets selection in the given table
 * @param tableView where the operation is being performed
 */
export const resetSelectionOnTable = action('RESET_SELECTION', (tableView: TableView) => ({
    tableView,
}));

/**
 * Select the next row in the direction given
 * @param tableView where the operation is being performed
 * @param selectionDirection the direction in which to select next row
 * @param mailListItemSelectionSource selection mode for the maillist item
 */
export const selectRowInDirection = action(
    'SELECT_ROW_IN_DIRECTION',
    (
        tableView: TableView,
        selectionDirection: SelectionDirection,
        mailListItemSelectionSource: MailListItemSelectionSource
    ) => {
        return isSelectMailItemCore(
            mailListItemSelectionSource,
            tableView,
            undefined /* rowKey */,
            selectionDirection
        )
            ? addCoreDatapointConfig(
                  {
                      name: 'SelectMailItem',
                      options: datapointOptions,
                  },
                  {
                      tableView,
                      selectionDirection,
                      mailListItemSelectionSource,
                  }
              )
            : addDatapointConfig(
                  {
                      name: 'SelectMailItemNonCritical',
                      options: datapointOptions,
                  },
                  {
                      tableView,
                      selectionDirection,
                      mailListItemSelectionSource,
                  }
              );
    }
);

export const setExpansionForRow = action(
    'SET_EXPANSION_FOR_ROW',
    (rowKey: string, mailListItemSelectionSource: MailListItemSelectionSource) => ({
        rowKey,
        mailListItemSelectionSource,
    })
);

/**
 * Select the row in the direction given in multiselect mode
 * @param tableView where the operation is being performed
 * @param mailListItemSelectionSource selection mode for the maillist item
 * @param selectionDirection direction in which the keyboard multiselection is happening (shift-up == Next, shift-down == Previous)
 * @param isCtrlOrCmdKeyDown whether user is also holding down ctrl or command key
 */
export const keyboardMultiSelectRow = action(
    'KEYBOARD_MULTI_SELECT_ROW',
    (
        tableView: TableView,
        mailListItemSelectionSource: MailListItemSelectionSource,
        selectionDirection: SelectionDirection,
        isCtrlOrCmdKeyDown: boolean
    ) => ({
        tableView,
        mailListItemSelectionSource,
        selectionDirection,
        isCtrlOrCmdKeyDown,
    })
);

/**
 * Collapse a given conversation when a user gives a keydown command
 * @param rowKey the row to collapse
 */
export const keyboardCollapseConversation = action(
    'KEYBOARD_COLLAPSE_CONVERSATION',
    (rowKey: string, tableView: TableView | undefined) => ({
        rowKey,
        tableView,
    })
);

export const resetBusStopStateMap = action('RESET_BUS_STOP_STATE_MAP');

/**
 * Selects a new row on row removal
 * @param tableView where the operation is being performed
 * @param lastSelectedRowIndex index of the row that was last selected
 * @param lastSelectedRowWasPinned whether last selected row was pinned
 * @param isRowNotRemovedYet whether the row has not been removed yet from the tableView
 */
export const selectNewItemUponRowRemoval = action(
    'SELECT_NEW_ITEM_UPON_ROW_REMOVAL',
    (
        tableView: TableView,
        lastSelectedRowIndex: number,
        lastSelectedRowWasPinned: boolean,
        isRowNotRemovedYet?: boolean
    ) => ({
        tableView,
        lastSelectedRowIndex,
        lastSelectedRowWasPinned,
        isRowNotRemovedYet,
    })
);
