//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ConversationTypeFragmentFragment = {
    __typename?: 'ConversationType';
    id: string;
    ConversationId: any;
    ConversationTopic: string;
    UniqueRecipients?: Array<string> | null;
    UniqueSenders?: Array<string> | null;
    LastDeliveryTime: string;
    Categories?: Array<string> | null;
    FlagStatus?: Types.FlagStatus | null;
    HasAttachments?: boolean | null;
    MessageCount?: number | null;
    GlobalMessageCount?: number | null;
    UnreadCount: number;
    GlobalUnreadCount?: number | null;
    Size?: number | null;
    ItemClasses?: Array<string> | null;
    Importance?: Types.ImportanceType | null;
    ItemIds?: Array<any> | null;
    GlobalItemIds?: Array<any> | null;
    LastModifiedTime?: string | null;
    InstanceKey?: string | null;
    Preview?: string | null;
    IconIndex?: Types.IconIndexType | null;
    DraftItemIds?: Array<any> | null;
    HasIrm?: boolean | null;
    IsTaggedForBigScreen?: boolean | null;
    LastDeliveryOrRenewTime: string;
    LastSentTime?: string | null;
    GlobalMentionedMe?: boolean | null;
    GlobalAtAllMention?: boolean | null;
    SortOrderSource?: Types.ExecuteSearchSortOrder | null;
    SpotlightIsVisible?: boolean | null;
    LastSender?: any | null;
    From?: any | null;
    EntityNamesMap?: any | null;
    HasExternalEmails?: boolean | null;
    ReturnTime?: string | null;
    HasSharepointLink?: boolean | null;
    HasAttachmentPreviews?: boolean | null;
    HasProcessedSharepointLink?: boolean | null;
    ConversationToMe?: boolean | null;
    ConversationCcMe?: boolean | null;
    ReactionsCount?: number | null;
    ParentFolderId?: {
        __typename?: 'FolderId';
        Id: string;
        ChangeKey?: string | null;
    } | null;
};
export const ConversationTypeFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ConversationTypeFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ConversationType' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ConversationId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ConversationTopic' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'UniqueRecipients' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'UniqueSenders' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'LastDeliveryTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Categories' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'FlagStatus' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasAttachments' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'MessageCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'GlobalMessageCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'UnreadCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'GlobalUnreadCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Size' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ItemClasses' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Importance' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ItemIds' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'GlobalItemIds' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'LastModifiedTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'InstanceKey' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Preview' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IconIndex' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'DraftItemIds' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasIrm' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsTaggedForBigScreen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'LastDeliveryOrRenewTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'LastSentTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'GlobalMentionedMe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'GlobalAtAllMention' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'SortOrderSource' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'SpotlightIsVisible' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'LastSender' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'From' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ParentFolderId' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'Id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ChangeKey' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'EntityNamesMap' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasExternalEmails' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ReturnTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasSharepointLink' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasAttachmentPreviews' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasProcessedSharepointLink' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ConversationToMe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ConversationCcMe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ReactionsCount' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConversationTypeFragmentFragment, unknown>;
