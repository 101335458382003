import type { ClientItemId } from 'owa-client-ids';
import { shouldShowAttachmentPreviewsForConversation } from 'owa-mail-attachment-previews';
import type { TableView } from 'owa-mail-list-store';
import { type TableQueryType } from 'owa-mail-list-store';
import type ConversationItem from 'owa-mail-list-store/lib/store/schema/ConversationItem';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { ConversationType } from 'owa-graph-schema';
import type BaseItemId from 'owa-service/lib/contract/BaseItemId';
import type ItemId from 'owa-service/lib/contract/ItemId';
import getConversationClientIdForSearchResult from 'owa-mail-search/lib/utils/getConversationClientIdForSearchResult';
import { getUserConfiguration } from 'owa-session-store';

function mapBaseItemIdsToItemIds(baseItemIds: BaseItemId[]): string[] {
    const itemIds: string[] = [];
    for (const baseItemId of baseItemIds) {
        const itemId: ItemId = <ItemId>{ ...baseItemId };
        itemIds.push(itemId.Id);
    }
    return itemIds;
}

//TODO: 22724 itemId should be ClientItemId so we don't need this function to get mailboxInfo
function getConversationClientId(
    conversationType: ConversationType,
    tableView: TableView
): ClientItemId {
    if (tableView?.tableQuery?.type == 1) {
        return getConversationClientIdForSearchResult(conversationType, tableView);
    }

    return {
        Id: conversationType.ConversationId.Id,
        mailboxInfo: getMailboxInfo(tableView),
    };
}

export default function mapConversationTypeToConversationItem(
    conversationType: ConversationType,
    tableView: TableView
): ConversationItem {
    const itemIds = mapBaseItemIdsToItemIds(conversationType.GlobalItemIds ?? []);
    const conversationItem: ConversationItem = {
        id: conversationType.ConversationId.Id,
        subject: conversationType.ConversationTopic,
        globalItemIds: itemIds,
        shouldShowAttachmentPreviews:
            !!getUserConfiguration().UserOptions?.ShowInlinePreviews &&
            shouldShowAttachmentPreviewsForConversation(conversationType, tableView.tableQuery),
        globalUnreadCount: conversationType.GlobalUnreadCount ?? 0,
        size: conversationType.Size ?? 0,
        clientId: getConversationClientId(conversationType, tableView),
        globalMessageCount: conversationType.GlobalMessageCount ?? 0,
        hasExternalEmails: conversationType.HasExternalEmails ?? false,
        hasSharepointLink: conversationType.HasProcessedSharepointLink ?? false,
    };

    return conversationItem;
}
