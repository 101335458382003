import { getEwsRequestString } from 'owa-datetime-formatters';
import { getFolderLastSyncTime } from 'owa-mail-list-store';

export function getInboxPausedDateTimeAsString(folderId: string) {
    const lastSyncTime = getFolderLastSyncTime(folderId);
    if (lastSyncTime) {
        return getEwsRequestString(lastSyncTime);
    }
    return null;
}
