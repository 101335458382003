//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ItemRowFragmentFragment = {
    __typename?: 'ItemRow';
    id: string;
    ItemId: any;
    AntispamUnauthenticatedSender?: boolean | null;
    Categories?: Array<string> | null;
    ConversationId?: any | null;
    DateTimeReceived?: string | null;
    DateTimeSent?: string | null;
    DisplayTo?: string | null;
    EntityNamesMap?: any | null;
    From?: any | null;
    HasAttachments?: boolean | null;
    HasProcessedSharepointLink?: boolean | null;
    IconIndex?: Types.IconIndex | null;
    Importance?: Types.Importance | null;
    InstanceKey?: string | null;
    IsDeliveryReceiptRequested?: boolean | null;
    IsDraft?: boolean | null;
    IsRead?: boolean | null;
    IsReadReceiptRequested?: boolean | null;
    IsSubmitted?: boolean | null;
    IsExternalSender?: boolean | null;
    ItemClass?: string | null;
    LastModifiedTime?: string | null;
    MentionedMe?: boolean | null;
    MessageToMe?: boolean | null;
    MessageCcMe?: boolean | null;
    Preview?: string | null;
    ReactionsCount?: number | null;
    ReceivedOrRenewTime?: string | null;
    ReturnTime?: string | null;
    Sensitivity?: Types.SensitivityType | null;
    Size?: number | null;
    SortOrderSource?: Types.ExecuteSearchSortOrder | null;
    SpotlightIsVisible?: boolean | null;
    Subject?: string | null;
    Flag?: {
        __typename?: 'Flag';
        FlagStatus?: Types.FlagStatus | null;
        StartDate?: string | null;
        DueDate?: string | null;
        CompleteDate?: string | null;
    } | null;
    ParentFolderId?: {
        __typename?: 'FolderId';
        Id: string;
        ChangeKey?: string | null;
    } | null;
};
export const ItemRowFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ItemRowFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemRow' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ItemId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AntispamUnauthenticatedSender' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'Categories' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ConversationId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'DateTimeReceived' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'DateTimeSent' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'DisplayTo' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'EntityNamesMap' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Flag' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'FlagStatus' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'StartDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'DueDate' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'CompleteDate' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'From' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasAttachments' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'HasProcessedSharepointLink' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IconIndex' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Importance' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'InstanceKey' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsDeliveryReceiptRequested' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsDraft' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsRead' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsReadReceiptRequested' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsSubmitted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'IsExternalSender' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ItemClass' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'LastModifiedTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'MentionedMe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'MessageToMe' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'MessageCcMe' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ParentFolderId' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'Id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ChangeKey' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'Preview' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ReactionsCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ReceivedOrRenewTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ReturnTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Sensitivity' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Size' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'SortOrderSource' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'SpotlightIsVisible' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'Subject' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ItemRowFragmentFragment, unknown>;
