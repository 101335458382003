import folderStore, { lazyUpdateFoldersParentFolder } from 'owa-folders';
import updateFolderCounts from 'owa-mail-folder-actions/lib/updateFolderCounts';
import { onUpdateFolderNotification } from 'owa-mail-actions/lib/folderNotificationActions';
import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import { mutator } from 'satcheljs';
import { isFeatureEnabled } from 'owa-feature-flags';

// TODO - Eventually these mutators should be removed with scenarios that depend on the folder counts
// start reading it from the cache
export default mutator(updateFolderCounts, actionMessage => {
    updateFolderCountsInternal(
        actionMessage.unreadCount,
        actionMessage.totalCount,
        actionMessage.folderId,
        actionMessage.isDeltaChange
    );
});

export const updateFolderCountsUponHierarchyPayload = mutator(
    onUpdateFolderNotification,
    actionMessage => {
        updateFolderCountsInternal(
            actionMessage.unreadCount,
            actionMessage.totalCount,
            actionMessage.folderId,
            false /* isDeltaChange */
        );
        if (isFeatureEnabled('fp-remove-apollo')) {
            // If the display name has changed, updated it and update the folder heirarchy
            const folder = folderStore.folderTable.get(actionMessage.folderId);
            if (folder && folder.displayName !== actionMessage.displayName) {
                folder.displayName = actionMessage.displayName;
                lazyUpdateFoldersParentFolder
                    .import()
                    .then(updateFoldersParentFolder =>
                        updateFoldersParentFolder(
                            actionMessage.parentFolderId,
                            actionMessage.folderId,
                            false /* addInCustomSortScenario */
                        )
                    );
            }
        }
    }
);

function updateFolderCountsInternal(
    unreadCount: number,
    totalCount: number,
    folderId: string,
    isDeltaChange: boolean
) {
    const folderTable = folderStore.folderTable;
    const folder = folderTable.get(folderId);

    if (folder) {
        if (isDeltaChange) {
            folder.UnreadCount += unreadCount;
            folder.totalMessageCount += totalCount;
        } else {
            folder.UnreadCount = unreadCount;
            folder.totalMessageCount = totalCount;
        }

        // If user is currently in a favorite persona folder and folder count changes, update cached
        // total count value so we don't show new message count badge.
        if (folder.pausedTotalCount && getFolderIdForSelectedNode() === folderId) {
            folder.pausedTotalCount = folder.totalMessageCount;
        }

        // Do not let counts fall below 0 in the store. This state could happen due to the timing of when
        // folder tree is loaded versus listview.
        folder.UnreadCount = Math.max(folder.UnreadCount, 0);
        folder.totalMessageCount = Math.max(folder.totalMessageCount, 0);
    }
}
